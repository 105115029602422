import '../../styles/globals.css'
import {SessionProvider} from "next-auth/react"
import type {AppProps} from 'next/app'
import {ChakraProvider} from '@chakra-ui/react'

import {extendTheme} from '@chakra-ui/react'
import {useEffect} from "react";
import {init, pageview} from "../core-web/services/analytics";
import {useRouter} from "next/router";
import ErrorBoundary from "../core-web/components/core/organisms/ErrorBoundary";

export {reportWebVitals} from 'next-axiom';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}


const theme = extendTheme({colors})

function MyApp({Component, pageProps: {session, ...pageProps},}: AppProps) {

  const router = useRouter();

  useEffect(() => {
    // Init
    init()
    // Track page views
    pageview(router.route)
    const handleRouteChange = (route: string) => pageview(route);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <ChakraProvider theme={theme}>
    <SessionProvider session={session}>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </SessionProvider>
  </ChakraProvider>
}

export default MyApp
