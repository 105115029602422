import React from "react";
import Sentry from "@sentry/nextjs";
import { Box, Button, Heading } from "@chakra-ui/react";

interface ErrorBoundaryProps {
  children: React.ReactChild | React.ReactChild[];
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error | null;
  errorInfo?: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log({ error, errorInfo });

    if(Sentry){
      Sentry.captureException(error);
    }

    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box margin={4}>
          <Heading marginBottom={2}>Oops, there is an error!</Heading>
          <Heading size="md" marginBottom={4}>
            {this.state.error?.toString()}
          </Heading>
          <Button colorScheme="blue" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </Button>
        </Box>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;