export const createPubEnv = () => {
  return {
    NOTION_CLIENT_ID: process.env.NEXT_PUBLIC_NOTION_CLIENT_ID,
    NOTION_REDIRECT_URL: process.env.NEXT_PUBLIC_NOTION_REDIRECT_URL,
    CAPTCHA_ENABLED: process.env.NEXT_PUBLIC_CAPTCHA_ENABLED === "true",
    AMPLITUDE_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    API_HOST: process.env.NEXT_PUBLIC_API_HOST,
    PREMIUM_URL: process.env.NEXT_PUBLIC_PREMIUM_URL,
    ANNUAL_PREMIUM_URL: process.env.NEXT_PUBLIC_ANNUAL_PREMIUM_URL,
    CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
  }
}

export const publicEnv = createPubEnv()

