
export const GA_TRACKING_ID = "G-M34V0567ES"

interface Gtag {
    (command: 'config', targetId: string, params?: { [key: string]: any }): void;
    (command: 'event', action: string, params?: { [key: string]: any }): void;
    (command: 'js', date: Date): void;
    (command: 'set', params: { [key: string]: any }): void;
}

declare global {
    interface Window {
        gtag: Gtag;
    }
}
export const gtag: Gtag = (...args) => {
  if (typeof window !== 'undefined' && window.gtag) {
    // @ts-ignore
    window.gtag(...args);
  }
}

