import * as amplitude from '@amplitude/analytics-browser';
import logger from "@app/core-app/logger";
import {publicEnv} from "@app/core-app/pub-env";
import {GA_TRACKING_ID, gtag} from "./gtag";


export const init = () => {
  const key = publicEnv.AMPLITUDE_KEY
  if (key) {
    amplitude.init(key)
  }
  logger.info({msg: "Analytics initialized", hasKey: (key?.length ?? 0) > 0})
}

export const pageview = (url: string) => {

  logger.info({msg: "New Page", url})
  const key = publicEnv.AMPLITUDE_KEY
  if (key) {
    amplitude.track("page_view", {
      url: url
    })
  }

  gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });

}

// log specific events happening.
// @ts-ignore
export const event = ({action, params}) => {
  const key = publicEnv.AMPLITUDE_KEY
  if (key) {
    amplitude.track(action, params)
  }

  gtag('event', action, params);
}

export const setAmplitudeUser = (userId: string) => {
  const key = publicEnv.AMPLITUDE_KEY
  if (key) {
    amplitude.setUserId(userId)
  }

  gtag('config', GA_TRACKING_ID, {
    user_id: userId,
  });
}

export const identify = (o: { [key: string]: string | number | boolean | undefined }) => {
  const key = publicEnv.AMPLITUDE_KEY
  if (key) {
    const identifyEvent = new amplitude.Identify();

    for (let k of Object.keys(o)) {
      const v = o[k]
      if (v !== undefined) {
        identifyEvent.set(k, v)
      }
    }

    amplitude.identify(identifyEvent)
  }
}
